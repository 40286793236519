package api

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.boss_serialization.BossDecoder
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.BossStruct
import net.sergeych.unikrypto.KeyIdentity
import net.sergeych.unikrypto.PrivateKey
import net.sergeych.unikrypto.PublicKey
import platform.convertPlatformIsntant
import platform.createSmartContract
import platform.instantToPlatform
import platform.unpackSmartContract
import kotlin.reflect.KType
import kotlin.reflect.typeOf
import kotlin.time.Duration.Companion.days

class ApiContract<T : Any>(
    private val ptype: KType,
    _payload: T? = null,
    val source: ByteArray? = null,
) {
    constructor(typ: KType, _payload: T? = null,
                issuerKey: PrivateKey, ownerId: KeyIdentity = issuerKey.id,
        expiresAt: Instant = Clock.System.now().plus(365.days*100))
            : this(typ, _payload) {
        sc.createRole("issuer", issuerKey)
        sc.createRoleLink("issuer","creator")
        sc.createRole("owner", ownerId)
        sc.addSigningKey(issuerKey)
        sc.expiresAt = expiresAt
    }

    constructor(typ: KType, _payload: T? = null,
                issuerId: KeyIdentity, ownerId: KeyIdentity = issuerId,
        expiresAt: Instant = Clock.System.now().plus(365.days*100))
            : this(typ, _payload) {
        sc.createRole("issuer", issuerId)
        sc.createRoleLink("issuer","creator")
        sc.createRole("owner", ownerId)
        sc.expiresAt = expiresAt
    }


    private val sc = source?.let { unpackSmartContract(source) } ?: createSmartContract()

    @Suppress("UNCHECKED_CAST")
    var optPayload: T?
        get() = sc.state["payload"]?.let {
            BossDecoder.decodeFrom(ptype, unpackFromUniversa(it) as Map<String, Any?>)
        }
        set(value) {
            sc.updateState { state ->
                state["payload"] = value?.let {
                    packForUniversa(BossEncoder.encodeToStruct(ptype, it))
                }
            }

        }

    var payload: T
        get() = optPayload ?: throw IllegalStateException("данные контракта не установлены")
        set(value) {
            optPayload = value
        }

    suspend fun addSignature(k: PrivateKey) {
        sc.addSignature(k)
    }

    suspend fun pack(): ByteArray = sc.pack()

    suspend fun isSignedBy(key: PublicKey) = sc.isSignedBy(key)
    suspend fun isSignedBy(keyId: KeyIdentity) = sc.isSignedBy(keyId)

    fun check(): String? = sc.check()
    suspend fun seal() {
        sc.seal()
    }

    init {
        if (_payload != null)
            this.optPayload = _payload
    }

    companion object {
        inline fun <reified T : Any> unpack(packed: ByteArray): ApiContract<T> =
            ApiContract(typeOf<T>(), null, packed)

        inline fun <reified T : Any> create(payload: T,
                                            issuerKey: PrivateKey,
                                            ownerId: KeyIdentity = issuerKey.id,
                                            expiresAt: Instant = Clock.System.now() + (1.days * 36500)): ApiContract<T> =
            ApiContract(typeOf<T>(), payload, issuerKey, ownerId, expiresAt)

        inline fun <reified T : Any> create(payload: T,
                                            issuerId: KeyIdentity,
                                            ownerId: KeyIdentity = issuerId,
                                            expiresAt: Instant = Clock.System.now() + (1.days * 36500)): ApiContract<T> =
            ApiContract(typeOf<T>(), payload, issuerId, ownerId, expiresAt)
    }
}

fun packForUniversa(x: Any?): Any? {
    return when(x) {
        is BossStruct -> {
            for ((k, v) in x) x[k] = packForUniversa(v)
            x
        }
        is List<*> -> x.map { packForUniversa(it) }
        is Instant -> instantToPlatform(x)
        else -> x
    }
}

@Suppress("UNCHECKED_CAST")
fun unpackFromUniversa(x: Any?): Any? {
    return when(x) {
        is Int -> x.toLong()
        is Map<*,*> -> {
            (x as Map<String,Any?>).map { (k, v) ->
                k to unpackFromUniversa(v)
            }.toMap()
        }
        is BossStruct -> {
//            for ((k, v) in x) x[k] = unpackFromUniversa(v)
//            x
            (x as Map<String,Any?>).map { (k, v) ->
                k to unpackFromUniversa(v)
            }.toMap()
        }
        is List<*> -> x.map { unpackFromUniversa(it) }
        null -> null
        else -> convertPlatformIsntant(x)
    }
}

