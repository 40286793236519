@file:UseSerializers(BigDecimalSerializer::class)
package api

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import tools.BigDecimalSerializer

@Serializable
data class ApiImportRequest(
    val user: ApiUser,
    val amount: BigDecimal,
    val description: String
) {

    init {
        if( amount <= 0 ) throw IllegalArgumentException("недопустимый размер импорта: $amount")
    }
}