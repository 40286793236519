package api

import kotlinx.serialization.Serializable
import net.sergeych.parsec3.CommandHost
import net.sergeych.parsec3.WithAdapter
import net.sergeych.unikrypto.PublicKey

class ServerApi<T : WithAdapter> : CommandHost<T>() {
    val version by command<Unit, VersionInfo>()

    // Login extras (most is handled by superlogin library)
    val isLoginNameAvailable by command<String, Boolean>()

    // Public key processing
    val getMyPublicKey by command<Unit, PublicKey>()
    val setMyPublicKey by command<ByteArray, Unit>()
    val getPublicKey by command<Long, PublicKey>()
    val getMyMainKey by command<Unit, ByteArray>()

    // Search users
    val findUser by command <String,List<ApiUser>>()
    val findUserByWallet by command <Long,ApiUser>()

    // Balance
    val getSystemWalletId by command<Unit,Long>()
    val getMyWallets by command<Unit,ApiMyWallets>()

    // Invoices
    val createInvoiceContract by command<ApiInvoice,ByteArray>()
    val registerInvoice by command<ByteArray,ApiInvoice>()
    val listInvoices by command<ApiRequestInvoices,List<ApiInvoice>>()
    val getInvoice by command<String,ApiFullInvoice>()
    val requestCreditPayment by command<String,Unit>()
    val payInvoice by command<String,ApiOperation>()

    // EXchanges
    val requestImport by command<ApiImportRequest,ApiOperation>()

    // transfers
    val prepareTransfer by command<ApiTransferRequest,ByteArray>()
    val transferFunds by command<ByteArray, ApiOperation>()
    val dropAwaitingTransfers by command<Unit,Unit>()

    // Operations
    val listOperations by command<ApiRequestOperations,List<ApiOperation>>()
    val getOperation by command<Long,ApiOperation>()
    val getOperationLog by command<GetOperationLogArgs,List<ApiLog>>()
    val getOperationContract by command<Long,ByteArray?>()

    // ----------- debug tools --------------
    val deleteTestUser by command<String, Unit>()
    val clearTestUserWallet by command<String,Unit>()
}

val IndigoApi = ServerApi<WithAdapter>()

@Serializable
data class VersionInfo(
    val version: String,
    val service: String,
    val status: String,
)
