package api

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ApiLog(
    val id: Long,
    val operationId: Long?,
    val userId: Long?,
    val walletId: Long?,
    val text: String,
    val isError: Boolean = false,
    val stackTrace: String? = null,
    val createdAt: Instant,
)
