package platform

import kotlinx.datetime.toKotlinInstant
import kotlin.js.Date

actual fun convertPlatformIsntant(x: Any): Any =
    when {
        x is Date -> x.toKotlinInstant().also {
            println("conv: 1 ${x::class.simpleName} -> ${it::class.simpleName}\n")
        }

        jsIsConstructorDate(x) ->
            (x as Date).toKotlinInstant()
                .also {
                    println("conv: 2 ${x} -> ${it::class.simpleName}\n")
                }

        else -> x
    }