@file:UseSerializers(BigDecimalSerializer::class)

package api

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import net.sergeych.mptools.truncateToSeconds
import net.sergeych.unikrypto.PublicKey
import net.sergeych.unikrypto.randomId
import tools.BigDecimalSerializer
import tools.bd

@Serializable
data class ApiInvoice(
    val vendor: ApiUser = ApiUser(""),
    val customer: ApiUser = ApiUser(""),
    val title: String="",
    val positions: List<Position> = listOf(),
    val total: BigDecimal =
        positions.fold(BigDecimal.ZERO) { acc, i -> acc + i.cost},
    val createdAt: Instant = Clock.System.now().truncateToSeconds(),
    val state: State = State.Draft,
    val stateUpdatedAt: Instant = Clock.System.now().truncateToSeconds(),
    val guid: String = randomId(14)
) {
    enum class State(val text: String){
        Draft("draft"), AwaitPayment("awaits payment"),
        Paid("paid"), Rejected("rejected");

        override fun toString(): String = text
    }

    @Serializable
    data class Position(
        val name: String,
        val quantity: BigDecimal,
        val unitPrice: BigDecimal,
    ) {
        val cost by lazy { unitPrice * quantity }

        companion object {
            val empty = Position("", BigDecimal.ZERO, BigDecimal.ZERO)
        }
    }

    init {
        val t2 =
            if (positions.isEmpty()) 0.bd
            else positions.map { it.cost }.reduce { acc, x -> acc + x }
        if( t2 != total )
            throw IllegalArgumentException("total does not match positions ($total != $t2")
    }
}

@Serializable
data class ApiRequestInvoices(
    val onlyIncoming: Boolean = false,
    val onlyOutgoing: Boolean = false,
    val filterPaid: Boolean? = null,
    val beforeGuid: String? = null,
    val limit: Int = 20
)

@Serializable
class ApiFullInvoice(
    val invoice: ApiInvoice,
    val packedContract: ByteArray
) {
    constructor(packedContract: ByteArray)
    : this(ApiContract.unpack<ApiInvoice>(packedContract).payload,packedContract)

    constructor(packedContract: ByteArray,acutalState: ApiInvoice.State)
    : this(ApiContract.unpack<ApiInvoice>(packedContract).payload.copy(state=acutalState),
        packedContract)

//    @Transient
    val contract: ApiContract<ApiInvoice> by lazy { ApiContract.unpack<ApiInvoice>(packedContract) }

    suspend fun isSignedBy(key: PublicKey): Boolean {
        return contract.isSignedBy(key)
    }

    init {
        if( invoice != contract.payload.copy(state=invoice.state) )
            throw IllegalArgumentException("invoice does not match the smartcontract")
    }
}